<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="vd_button_group vg_mtb_16">
        <el-button type="danger" size="small" @click="doDelete()" :disabled="!btn.delete"
          ><i class="el-icon-delete"></i>
          删除
        </el-button>
        <el-button type="info" size="small" class="vd_export" icon="el-icon-refresh-right" @click="buttonRefresh()"> 刷新 </el-button>
        <el-date-picker
          class="vg_ml_8 vg_mr_8 topDateRangeSearch"
          size="small"
          v-model="dateRange"
          type="daterange"
          range-separator="至"
          start-placeholder="录入开始日期"
          end-placeholder="录入结束日期"
        />
        <el-button type="primary" size="small" icon="el-icon-search" @click="getFequs()"> 查询</el-button>
        <el-button type="success" size="small" class="vg_ml_8" @click="jump('makeSampleControlTable')">打样控制表 </el-button>
      </div>
      <div class="vg_mb_8">
        <el-radio-group v-model="requ_type" size="small" @input="getFequs()">
          <el-radio-button :label="1">一部</el-radio-button>
          <el-radio-button :label="2">辅料部</el-radio-button>
          <el-radio-button :label="3">二部</el-radio-button>
        </el-radio-group>
        <el-button v-if="requ_type !== 1 || isSecondPurchaseDepartment" size="small" style="margin-left: 20px" type="warning" @click="copyById()">
          复制并编辑
        </el-button>
      </div>
      <DynamicUTable
        ref="multiTable"
        :tableData="tableData"
        :columns="subTableProperties"
        v-loading="loadFlag"
        :totalPage="totalPage"
        :need-fixed-height="true"
        :need-page-sizes="true"
        :page-size="50"
        @select="selectRows"
        @selection-change="handleSelectionChange"
        @select-all="selectRows"
        @row-dblclick="dbClickJp"
        @getTableData="getFequs"
        :row-style="setRowStyle"
        :need-search="true"
        :needChangeColumnProperties="true"
      >
        <template v-slot:fequ_dyj_status="scope">
          <el-tag type="success" v-if="scope.row.fequ_dyj_status === 1" size="mini"> 已同步</el-tag>
          <el-tag type="danger" v-else size="mini"> 未同步</el-tag>
        </template>
        <template v-slot:residue_time="scope">
          <div style="text-align: center">
            <el-tag type="danger" v-if="scope.row.residue_time_type === 1" size="mini">延迟交样 {{ scope.row.residue_time }} 天 </el-tag>
            <el-tag type="danger" v-else-if="scope.row.residue_time === 1 || scope.row.residue_time === 0" size="mini">
              剩余 {{ scope.row.residue_time }} 天
            </el-tag>
            <el-tag type="warning" v-else-if="scope.row.residue_time === 2" size="mini"> 剩余 {{ scope.row.residue_time }} 天 </el-tag>
            <el-tag type="warning" v-else-if="scope.row.residue_time === 3" size="mini"> 剩余 {{ scope.row.residue_time }} 天 </el-tag>
            <el-tag type="danger" v-else-if="scope.row.residue_time < 0" size="mini">
              超时 {{ scope.row.residue_time }}
              天
            </el-tag>
            <el-tag type="success" v-else-if="scope.row.residue_time === 999" size="mini"> 已完成</el-tag>
            <el-tag type="success" v-else size="mini"> 剩余 {{ scope.row.residue_time }} 天</el-tag>
          </div>
        </template>
        <template v-slot:status="scope">
          <div style="text-align: center">
            <el-tag :type="helper.getStatusName(scope.row.status).type" size="mini">
              {{ helper.getStatusName(scope.row.status).name }}
            </el-tag>
          </div>
        </template>
      </DynamicUTable>
    </el-card>
  </div>
</template>

<script>
import { getNoCatch, postNoCatch } from '@api/request';
import { fequAPI } from '@/api/modules/fequ';
import pubPagination from '@/components/common/pubPagination';
import SearchTable from '@/components/table/SearchTableV2.vue';
import { getDateNoTime } from '@assets/js/dateUtils';
import { getArrayIds } from '@assets/js/arrayUtils';
import UrlEncode from '@assets/js/UrlEncode';
import { cloneDeep } from 'lodash';
import { getDept, getPageSortData, setTime } from '@api/public';
import DynamicUTable from '@/components/table/DynamicUTable.vue';

export default {
  name: 'FequList',
  components: {
    SearchTable,
    pubPagination,
    DynamicUTable
  },
  data() {
    return {
      dateRange: [],
      requ_type: 1,
      tableProperties: [
        {
          prop: 'status',
          label: '单据状态',
          itemType: 'select',
          fixed: 'left',
          options: [
            { value: 0, label: '草拟' },
            { value: 1, label: '在批' },
            { value: 2, label: '生效' }
          ]
        },
        {
          prop: 'fequ_no',
          itemType: 'input',
          label: '工厂打样单号',
          input: true,
          sortable: false
        },
        {
          prop: 'fequ_state',
          itemType: 'select',
          label: '打样进度',
          options: [
            { value: 0, label: '未开始' },
            { value: 1, label: '进行中' },
            { value: 2, label: '已完成' },
            { value: 3, label: '取消打样' },
            { value: 4, label: '部分取消' }
          ],
          sortable: true,
          formatter: val => this.fequStateFormatter(val)
        },
        {
          prop: 'requ_no',
          itemType: 'input',
          label: '委托打样单号',
          input: true,
          sortable: false
        },
        {
          prop: 'fsuppAbbrList',
          itemType: 'select',
          options: [],
          label: '工厂简称',
          sortable: true,
          multiple: true,
          filterable: true,
          collapseTags: true,
          labelWidth: 200,
          needOtherColumn: true,
          formatter: row => row.supp_abbr
        },
        {
          prop: 'prod_no_strs',
          itemType: 'input',
          label: '我司货号',
          sortable: false,
          overflowTooltip: true
        },
        {
          prop: 'prod_cust_nos',
          itemType: 'input',
          label: '客户货号',
          sortable: false,
          overflowTooltip: true,
          visible: false
        },
        {
          prop: 'fequSmplQuotsList',
          itemType: 'select',
          options: [
            { value: 1, label: '打样并报价' },
            { value: 2, label: '试样报价' },
            { value: 3, label: '仅打样' },
            { value: 4, label: '估价' },
            { value: 5, label: '返工' },
            { value: 6, label: '仅报价' },
            { value: 7, label: '其他' }
          ],
          label: '委托类型',
          sortable: false,
          multiple: true,
          filterable: true,
          collapseTags: true,
          labelWidth: 120,
          needOtherColumn: true,
          formatter: row => row.fequ_smpl_quots,
          visible: false
        },
        {
          prop: 'old_cust_prod_nos',
          itemType: 'input',
          label: '老ERP我司货号',
          sortable: false,
          overflowTooltip: true
        },
        {
          prop: 'prod_names',
          itemType: 'input',
          label: '产品名称',
          sortable: false,
          overflowTooltip: true
        },
        {
          prop: 'fequ_dept_name',
          itemType: 'input',
          label: '提交审核部门',
          sortable: false,
          input: true
        },
        {
          prop: 'requ_sample',
          itemType: 'input',
          label: '是否有工艺单',
          needOtherColumn: true,
          formatter: val => this.fequSample(val),
          input: false,
          sortable: false
        },
        {
          prop: 'custAbbrList',
          itemType: 'select',
          options: [],
          label: '客户简称',
          sortable: false,
          multiple: true,
          filterable: true,
          collapseTags: true,
          labelWidth: 200,
          needOtherColumn: true,
          formatter: row => row.cust_abbr
        },
        {
          prop: 'requStffNameList',
          itemType: 'select',
          options: [],
          label: '打样委托人',
          sortable: false,
          filterable: true,
          needOtherColumn: true,
          multiple: true,
          collapseTags: true,
          labelWidth: 200,
          formatter: row => row.requ_stff_name
        },
        {
          prop: 'requStffDeptNameList',
          itemType: 'select',
          options: [],
          label: '打样委托部门',
          sortable: false,
          multiple: true,
          filterable: true,
          collapseTags: true,
          labelWidth: 200,
          needOtherColumn: true,
          formatter: row => row.requ_stff_dept_name,
          visible: false
        },
        {
          prop: 'residue_time',
          itemType: 'input',
          input: false,
          label: '剩余需交样天数',
          sortable: true
        },
        {
          prop: 'requ_pedate',
          itemType: 'date',
          label: '要求交样日期',
          formatter: val => getDateNoTime(val, true),
          sortable: true
        },
        {
          prop: 'fequTakerList',
          itemType: 'select',
          options: [],
          label: '打样接收人',
          sortable: false,
          filterable: true,
          overflowTooltip: true,
          needOtherColumn: true,
          multiple: true,
          collapseTags: true,
          labelWidth: 200,
          formatter: row => row.fequ_taker_strs
        },
        {
          prop: 'requ_pricer_name',
          itemType: 'input',
          label: '核价接收人',
          sortable: false,
          labelWidth: 200,
          input: true
        },
        {
          prop: 'requ_quoter_name',
          itemType: 'input',
          label: '报价接收人',
          sortable: false,
          labelWidth: 200,
          input: true
        },
        {
          prop: 'fequ_dyj_status',
          input: false,
          itemType: 'input',
          label: '同步打样间状态',
          sortable: false,
          formatter: val => {
            if (val === 1) {
              return '已同步';
            } else {
              return '未同步';
            }
          }
        },
        {
          prop: 'create_time',
          label: '录入时间',
          itemType: 'input',
          labelWidth: '120px',
          input: false,
          sortable: true,
          formatter: val => getDateNoTime(val, true)
        }
      ],
      loadFlag: true,
      totalPage: 0,
      tableData: [],
      multiSelection: [],
      btn: {},
      fequStateList: [
        { value: 0, label: '未开始' },
        { value: 1, label: '进行中' },
        { value: 2, label: '已完成' },
        { value: 3, label: '取消打样' },
        { value: 4, label: '部分取消' }
      ],
      fsuppAbbrList: [],
      isSecondPurchaseDepartment: false
    };
  },
  computed: {
    subTableProperties() {
      let cloneTableProperties = Object.values(cloneDeep(this.tableProperties));
      const requType = Number(this.requ_type);
      this.$refs.multiTable?.reloadTable();
      // if (requType === 1) {
      //   // 移除元素
      //   const fromIndex = cloneTableProperties.findIndex(x => x.label === '打样接收人');
      //   const item = cloneTableProperties.splice(fromIndex, 1)[0];
      //   // 插入元素
      //   const toIndex = cloneTableProperties.findIndex(x => x.label === '提交审核部门');
      //   cloneTableProperties.splice(toIndex + 1, 0, item);
      // }
      if (requType === 2) {
        const start = ['selection', 'index', 'cust_abbr'];
        const ending = ['fequ_no'];
        const startProps = [];
        const middleProps = [];
        const endProps = [];
        cloneTableProperties.forEach(property => {
          switch (property.label) {
            case '打样委托部门':
              property.visible = true;
              break;
            case '客户货号':
              property.visible = true;
              break;
            case '委托类型':
              property.visible = true;
              break;
          }
          if (start.includes(property.prop)) {
            startProps.push(property);
          } else if (!ending.includes(property.prop)) {
            middleProps.push(property);
          } else {
            endProps.push(property);
          }
        });
        return startProps.concat(middleProps).concat(endProps);
      }
      return cloneTableProperties;
    }
  },
  async mounted() {
    if (this.$route.query.requ_no) this.$refs.multiTable.searchForm.requ_no = this.$route.query.requ_no;
    switch (this.$cookies.get('userInfo').dept_id) {
      case 6: //采购一部
        this.requ_type = 1; //毛绒
        break;
      case 20: //辅料部
        this.requ_type = 2; //辅材
        break;
      case 7: //采购二部
        this.requ_type = 3; //非毛绒
        break;
    }
    this.isSecondPurchaseDepartment = (await getDept(['采购二部'], 'ids')).includes(this.$cookies.get('userInfo').dept_id);
    await this.getFequs();
    this.tableProperties = await getPageSortData({ user_id: this.$cookies.get('userInfo').user_id, perm_id: this.$route.query.perm_id }, this.tableProperties);
  },
  watch: {
    $route(to, from) {
      if (to.path === '/fequ_list' || from.path === '/fequ_edit') {
        this.getFequs();
      }
    }
  },
  methods: {
    getFequs() {
      this.loadFlag = true;
      let searchForm = cloneDeep(this.$refs.multiTable.searchForm);
      searchForm.requ_type = this.requ_type; //单据类型
      setTime(searchForm, 'start_time', 'end_time', this.dateRange || [], true);
      searchForm.fsuppAbbrList = searchForm.fsuppAbbrList?.toString();
      searchForm.requStffNameList = searchForm.requStffNameList?.toString();
      searchForm.fequTakerList = searchForm.fequTakerList?.toString();
      searchForm.custAbbrList = searchForm.custAbbrList?.toString();
      searchForm.fequSmplQuotsList = searchForm.fequSmplQuotsList?.toString();
      searchForm.requStffDeptNameList = searchForm.requStffDeptNameList?.toString();
      getNoCatch(fequAPI.getFequs, searchForm).then(({ data }) => {
        this.tableData = data.list;
        this.totalPage = data.total;
        this.btn = data.btn;
        this.subTableProperties.forEach(item => {
          if (item.prop === 'fsuppAbbrList') item.options = data.fsuppAbbrList;
          if (item.prop === 'requStffNameList') item.options = data.requStffNameList;
          if (item.prop === 'fequTakerList') item.options = data.fequTakerList;
          if (item.prop === 'custAbbrList') item.options = data.custAbbrList;
          if (item.prop === 'requStffDeptNameList') item.options = data.requStffDeptNameList;
        });
        setTimeout(() => {
          this.loadFlag = false;
        }, 500);
      });
    },
    // 刷新
    buttonRefresh() {
      this.dateRange = [];
      this.$refs.multiTable.resetFields();
      this.getFequs();
    },
    // 多选获取公司抬头信息
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    selectRows(selection) {
      this.btn.delete = this.helper.isDelete(selection);
    },
    doDelete() {
      if (this.multiSelection.length === 0) {
        this.$message({ type: 'warning', message: '请至少选择一条数据！' });
      } else {
        this.mBox();
      }
    },
    //删除提示
    mBox() {
      this.$confirm('确定删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.delRow();
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
          this.$refs.multiTable.clearSelection();
        });
    },
    // 删除
    delRow() {
      let ids = getArrayIds(this.multiSelection, 'fequ_id');
      postNoCatch(fequAPI.deleteFequByIds, { fequ_id_list: ids }).then(({ data }) => {
        this.$message.success('删除成功!');
        if (ids.length === this.tableData.length) {
          if (this.currentPage > 1) {
            this.currentPage = this.currentPage - 1;
            this.$refs.pubPagination.currentPage = this.currentPage;
          }
        }
        this.getFequs();
      });
    },
    dbClickJp(row) {
      let flag = false;
      if (row.fequ_dept_name === '采购二部') flag = true;
      const permId = this.$route.query.perm_id;
      this.jump('/fequ_edit', {
        key: UrlEncode.encode(
          JSON.stringify({
            perm_id: permId,
            form_id: row.fequ_id,
            showFlag: flag
          })
        )
      });
    },
    //打样状态
    fequStateFormatter(fequ_state) {
      return this.fequStateList.find(({ value }) => value === fequ_state).label;
    },
    fequSample(row) {
      if (row.requ_sample === 0) {
        return '否';
      } else if (row.requ_sample === 1) {
        return '是';
      }
    },
    copyById() {
      if (this.multiSelection.length !== 1) return this.$message.error('请选择一条数据复制！');
      fequAPI.copy_fequ_by_id({ fequ_id: this.multiSelection[0].fequ_id }).then(({ data }) => {
        let falg = false;
        if (data.fequ_dept_name === '采购一部') {
          falg = false;
        } else if (data.fequ_dept_name === '采购二部') {
          falg = true;
        } else {
          falg = undefined;
        }
        const permId = this.$route.query.perm_id;
        this.jump('/fequ_edit', {
          key: UrlEncode.encode(
            JSON.stringify({
              perm_id: permId,
              form_id: data.form_id,
              showFlag: falg
            })
          )
        });
      });
    },
    setRowStyle({ row }) {
      if (this.requ_type === 2) {
        switch (Number(row.fequ_qutc_start)) {
          case 0:
            return { background: 'white' };
          case 1:
            return { background: 'orange' };
          case 2:
            return { background: '#67c23a' };
        }
      }
    }
  }
};
</script>

<style scoped lang="scss"></style>
